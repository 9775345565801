import React from 'react';import { Link } from 'react-router-dom';


import image_imageview_12 from '../../../assets/Logo BN.png';
import styles from './mobilemenu.module.css';

/**
 * Mobilemenu component.
 * @component
 * @type {React.FC}
 * 
 * @returns {React.ReactElement} The Mobilemenu component.
 */
const Mobilemenu = () =>{
    
    const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
};
    
    function aguaRender() {
        return(
            <main custom-agua-item="screen"  className={styles.Mobilemenu}>
                
<section custom-agua-item="layer" className={styles.mobilemenuheader}   >
<section custom-agua-item="layer" className={styles['layer-772-859242391fdf41118dd94614f88b7356']}   >
<section custom-agua-item="layer" className={styles['layer-92-0de1bc8ae8404d07810cd756cb48dc37']}   >
<Link to="/Home"><img custom-agua-item="imageView" className={styles['imageview-12-bbe37002c45b48f1bdddae921c76c61c']}  alt="imageview-12" src={ image_imageview_12}  /></Link>
<p custom-agua-item="text" className={styles['text-1642-faf822e46705435cba72ee32d7ec2fe1']}   >Catatumbo Literario</p>
</section>
<section custom-agua-item="layer" className={styles['layer-102-369419b08c7549669be9becb1306982f']}   >
<section custom-agua-item="layer" className={styles['layer-112-29dca7bcdc0047b7af8eb210c16fe1cf']}   onClick={() => openInNewTab("https://letras-catatumbo.bubbleapps.io/version-test/cuentos?debug_mode=true")}>
<p custom-agua-item="text" className={styles['text-42-38e7c9f4912e4c1881df4c237e7981df']}   >Cursos</p>
</section>
<section custom-agua-item="layer" className={styles['layer-122-fbbc056ba4314a5cb43f0f35db04000f']}   >
<Link to="/Sales"><section custom-agua-item="layer" className={styles['layer-2732-12e591fcbaa34a49938e9b06b36724c8']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-52-0c35d5447a0945279ea419ec3facb4d0']}   >Publicaciones</p>
</section>
<section custom-agua-item="layer" className={styles['layer-132-5ac9785561ea4a8a915e82d526d1fd91']}   >
<p custom-agua-item="text" className={styles['text-62-aad7251604604f4fa932d8d5084c424a']}   >Nosotros</p>
</section>
</section>
<section custom-agua-item="layer" className={styles['layer-782-1f2c8f37ac764c2887ac52b1b5a534b5']}   >
<section custom-agua-item="layer" className={styles['layer-142-ce3bde4a17694383bf1074e01a8d9687']}   onClick={() => openInNewTab("https://letras-catatumbo.bubbleapps.io/version-test/cuentos?debug_mode=true")}>
<p custom-agua-item="text" className={styles['text-32-a63d6350d5764c09811e097b0fc87de8']}   onClick={() => openInNewTab("https://letras-catatumbo.bubbleapps.io/version-test/cuentos?debug_mode=true")}>Prueba nuestra IA</p>
</section>
<section custom-agua-item="layer" className={styles['layer-792-52818480eef34b7295ae5e9190406891']}   >
<span custom-agua-item="icon" className={styles['icon-72-59d5fe7086db4537b2319aa7c7a38efa']}  >keyboard_arrow_up</span>
</section>
</section>
</section>
</section>
<section custom-agua-item="layer" className={styles.mobilemenubody}   >
<section custom-agua-item="layer" className={styles['layer-431-b739e5f6d9114a8a8a33cbe8d2e27e21']}   >
<section custom-agua-item="layer" className={styles['layer-209-05cb0d6c8b514df5972cb92cd115b6ba']}   >
<p custom-agua-item="text" className={styles['text-96-a995951b63194a5f9ea60729766925d2']}   >Cursos</p>
</section>
<section custom-agua-item="layer" className={styles['layer-210-bd732a649a394d2387582ca0ddaf8c37']}   >
<p custom-agua-item="text" className={styles['text-97-9f40c0371fcb4fff8eb3d25b93598af2']}   >Publicaciones</p>
</section>
</section>
<section custom-agua-item="layer" className={styles['layer-432-566386ba486746c4abbc6921aaafc202']}   ></section>
</section>

            </main>
        );
    };

    return aguaRender();
}

export default Mobilemenu;
            