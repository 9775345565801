import React from 'react';import { Link } from 'react-router-dom';


import image_imageview_1 from '../../../assets/Logo BN.png';
import styles from './home.module.css';

/**
 * Home component.
 * @component
 * @type {React.FC}
 * 
 * @returns {React.ReactElement} The Home component.
 */
const Home = () =>{
    
    const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
};
    
    function aguaRender() {
        return(
            <main custom-agua-item="screen"  className={styles.Home}>
                
<section custom-agua-item="layer" className={styles.homeheader}   >
<section custom-agua-item="layer" className={styles['layer-77-00fa2b353ea34334931316459fab54a2']}   >
<section custom-agua-item="layer" className={styles['layer-9-d66d4b3ba1814e06b4d09ed0af08cd9e']}   >
<Link to="/Home"><img custom-agua-item="imageView" className={styles['imageview-1-65643afc344645e6b2a70beb0fb814c4']}  alt="imageview-1" src={ image_imageview_1}  /></Link>
<p custom-agua-item="text" className={styles['text-164-b2c908768cd24bb982f1e0614dea4454']}   >Catatumbo Literario</p>
</section>
<section custom-agua-item="layer" className={styles['layer-10-ffda0b91b6aa4453b9537feb5eb35460']}   >
<section custom-agua-item="layer" className={styles['layer-11-fa9827d3813c48208efd7b90a8ed50a0']}   onClick={() => openInNewTab("https://letras-catatumbo.bubbleapps.io/version-test/cuentos?debug_mode=true")}>
<p custom-agua-item="text" className={styles['text-4-613defb6024e44b19e9844de477556cd']}   >Cursos</p>
</section>
<section custom-agua-item="layer" className={styles['layer-12-18b55c9074e649a0892cc571db76d8ce']}   >
<Link to="/Sales"><section custom-agua-item="layer" className={styles['layer-273-c1ca8a3211414523ab38d13086ad87a9']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-5-20c8f25da96e4fdb96fccbf78852bf8b']}   >Publicaciones</p>
</section>
<section custom-agua-item="layer" className={styles['layer-13-c8d6ca2882aa4972adda8f6a77a322f3']}   >
<p custom-agua-item="text" className={styles['text-6-7f821579cea74199bbb4bd832ef05a9a']}   >Nosotros</p>
</section>
</section>
<section custom-agua-item="layer" className={styles['layer-78-e38fda5c5ef14b66819c33af5b662311']}   >
<section custom-agua-item="layer" className={styles['layer-14-15bab501101f4b628529023c8e6deafd']}   onClick={() => openInNewTab("https://letras-catatumbo.bubbleapps.io/version-test/cuentos?debug_mode=true")}>
<p custom-agua-item="text" className={styles['text-3-01c8b08e0c9d4d4abc99accdaa3d6377']}   onClick={() => openInNewTab("https://letras-catatumbo.bubbleapps.io/version-test/cuentos?debug_mode=true")}>Prueba nuestra IA</p>
</section>
<section custom-agua-item="layer" className={styles['layer-79-003a092e37a442e78a01f6cf6edf4728']}   >
<span custom-agua-item="icon" className={styles['icon-7-3d516fc0f824448095c9b73eedbc6185']}  >menu</span>
</section>
</section>
</section>
</section>
<section custom-agua-item="layer" className={styles.homebody}   >
<section custom-agua-item="layer" className={`${styles['homebodysection1-orientation']} ${styles['homebodysection1']}`}   >
<section custom-agua-item="layer" className={styles['layer-26-9e59334620f84273958101c53fb222dd']}   >
<p custom-agua-item="text" className={styles['text-10-67b852ea08654d03ac0b800584c93832']}   >¡Comienza tu viaje de escritura con la ayuda de IA!</p>
<p custom-agua-item="text" className={styles['text-11-5fbe61ac4de5486580721b13716f972d']}   >Realiza una prueba gratis de los primeros niveles de nuestro cursos de escritura para empezar a crear las historias que tanto haz deseado.</p>
<section custom-agua-item="layer" className={styles['layer-32-bb4b72fd9c6c4ae88d349a7609a2f8f9']}   onClick={() => openInNewTab("https://letras-catatumbo.bubbleapps.io/version-test/cuentos?debug_mode=true")}>
<p custom-agua-item="text" className={styles['text-14-8a8cdf21bca5437196b933150f8a729b']}   onClick={() => openInNewTab("https://letras-catatumbo.bubbleapps.io/version-test/cuentos?debug_mode=true")}>Comienza ahora</p>
</section>
</section>
<section custom-agua-item="layer" className={styles['layer-27-745fbed9919e49eb9426742fc6a4f07a']}   >
<section custom-agua-item="layer" className={styles['layer-30-15328a0ce7234845ba67d4931ba898fb']}   ></section>
</section>
</section>
<section custom-agua-item="layer" className={`${styles['homebodysection2-orientation']} ${styles['homebodysection2']}`}   >
<section custom-agua-item="layer" className={styles['layer-28-abdfefcfae4a46db8ab4e493e40fa091']}   >
<p custom-agua-item="text" className={styles['text-12-9fec29ad465c4ffc9c6045ca03b93cd9']}   >¡Diviértete publicando tu historia, el primer paso es fácil!</p>
<p custom-agua-item="text" className={styles['text-13-ca70ef61fdf44348865ee61256480146']}   >Convierte tus ideas en libros con nuestra editorial de autores independientes.</p>
<section custom-agua-item="layer" className={styles['layer-33-e82c15ae062044abb90ae3a6e77684b3']}   onClick={() => openInNewTab("https://calendly.com/marianaquinterot")}>
<p custom-agua-item="text" className={styles['text-15-86170b23937e4d6c828745e0d58f8111']}   >Agendate</p>
</section>
</section>
<section custom-agua-item="layer" className={styles['layer-29-bafcf9957cf8408dab8643b08b036763']}   >
<section custom-agua-item="layer" className={styles['layer-31-8373c27c8df4437db5d33c64b91dc9b8']}   ></section>
</section>
</section>
<section custom-agua-item="layer" className={styles.homebodysection3}   >
<section custom-agua-item="layer" className={styles['layer-34-dab934938956487e9898d0e6b80e04fe']}   >
<p custom-agua-item="text" className={styles['text-16-cf2ea0edae3f4d19a9fd45a25eaf5b86']}   >Conoce a nuestros autores:</p>
<p custom-agua-item="text" className={styles['text-17-6026b641a5e14b23be0c83a4f25238d5']}   >En Catatumbo creamos un hogar para todos los autores independientes que han tomado la decisión de publicar sus más grandes ideas.</p>
</section>
<section custom-agua-item="layer" className={`${styles['layer-35-5aa2b4bd372a42b991f515ca877824a4-orientation']} ${styles['layer-35-5aa2b4bd372a42b991f515ca877824a4']}`}   >
<section custom-agua-item="layer" className={styles['layer-36-ecd5eef5a5d840afbca4673e8c58771b']}   >
<section custom-agua-item="layer" className={styles['layer-37-928829e508d74e30931f1a126424289c']}   ></section>
<p custom-agua-item="text" className={styles['text-18-50fc46f3c6d04c7c8e58a31406e1a108']}   >Mariana Quintero</p>
</section>
<section custom-agua-item="layer" className={styles['layer-38-5408fb5e3523452f87ee0536092f9bfd']}   >
<section custom-agua-item="layer" className={styles['layer-39-1726d69db7f744f594b20750fcfab301']}   ></section>
<p custom-agua-item="text" className={styles['text-19-8d35a78a63f54fb38b9777990f266a27']}   >Emily Blanco</p>
</section>
<section custom-agua-item="layer" className={styles['layer-108-19f3d87e55b344c98f852aa6f01163e8']}   >
<section custom-agua-item="layer" className={styles['layer-109-6f03c8d1627846f5acb29feb8cabc558']}   ></section>
<p custom-agua-item="text" className={styles['text-51-946b62bf87ef420085d693602bc3871b']}   >Daniela Cortez</p>
</section>
</section>
<section custom-agua-item="layer" className={styles['layer-40-423f31be3b5d439f918b492f4a6eed46']}   >
<section custom-agua-item="layer" className={styles['layer-41-a6852749e9e142358aa80f489eef2633']}   onClick={() => openInNewTab("mailto:mariana@letrascatatumbo")}>
<p custom-agua-item="text" className={styles['text-20-fdeaba3d38e9424faa63cbf76a093d18']}   >Publica con nosotros</p>
</section>
</section>
</section>
</section>

            </main>
        );
    };

    return aguaRender();
}

export default Home;
            