import React from 'react';import { Link } from 'react-router-dom';


import image_imageview_12 from '../../../assets/Logo BN.png';
import styles from './sales.module.css';

/**
 * Sales component.
 * @component
 * @type {React.FC}
 * 
 * @returns {React.ReactElement} The Sales component.
 */
const Sales = () =>{
    
    const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
};
    
    function aguaRender() {
        return(
            <main custom-agua-item="screen"  className={styles.Sales}>
                
<section custom-agua-item="layer" className={styles.salesheader}   >
<section custom-agua-item="layer" className={styles['layer-772-4ae72189978f496695d2199891dfe8f4']}   >
<section custom-agua-item="layer" className={styles['layer-95-ce0ec84225e94745ae47e6476749c2ea']}   >
<Link to="/Home"><img custom-agua-item="imageView" className={styles['imageview-12-4069489e01784cc29e71c900366e8549']}  alt="imageview-12" src={ image_imageview_12}  /></Link>
<p custom-agua-item="text" className={styles['text-1642-07dd3f34927241c294d5d36b6ba7cf8d']}   >Catatumbo Literario</p>
</section>
<section custom-agua-item="layer" className={styles['layer-102-2736960450c943009864a03a77fc6b74']}   >
<section custom-agua-item="layer" className={styles['layer-112-96c790bb7f8c4113a6bdb0058142dc28']}   onClick={() => openInNewTab("https://letras-catatumbo.bubbleapps.io/version-test/cuentos?debug_mode=true")}>
<p custom-agua-item="text" className={styles['text-43-cf7bc1ee6b714597ad43c7b10aa4645e']}   >Cursos</p>
</section>
<section custom-agua-item="layer" className={styles['layer-122-b22694d629a748488b3a889750732366']}   >
<Link to="/Sales"><section custom-agua-item="layer" className={styles['layer-2732-5e539b451c5e445c8130a1a6545e61ce']}   ></section></Link>
<p custom-agua-item="text" className={styles['text-52-7fcb1e3628a84a10a6985016c86ba5fe']}   >Publicaciones</p>
</section>
<section custom-agua-item="layer" className={styles['layer-132-c8b21493eb464efbbce47bf1e18df6a0']}   >
<p custom-agua-item="text" className={styles['text-62-78b3c5f6f6f84079864223aad3c55f5b']}   >Nosotros</p>
</section>
</section>
<section custom-agua-item="layer" className={styles['layer-782-865cdebedfb040aa9fcc4a0be33b3a6c']}   >
<section custom-agua-item="layer" className={styles['layer-142-c0b473d57dab4ff189313558ddceb453']}   onClick={() => openInNewTab("https://letras-catatumbo.bubbleapps.io/version-test/cuentos?debug_mode=true")}>
<p custom-agua-item="text" className={styles['text-32-413ff3a2c0d444fe9095bb5f8d565b77']}   onClick={() => openInNewTab("https://letras-catatumbo.bubbleapps.io/version-test/cuentos?debug_mode=true")}>Prueba nuestra IA</p>
</section>
<section custom-agua-item="layer" className={styles['layer-792-bdf6e428bcc2453fa88a25fa61b31cd1']}   >
<span custom-agua-item="icon" className={styles['icon-72-65a9ba0814cf40f3a014a1cb4d0b69a6']}  >menu</span>
</section>
</section>
</section>
</section>
<section custom-agua-item="layer" className={styles.salesbody}   >
<section custom-agua-item="layer" className={styles.salessection1}   >
<section custom-agua-item="layer" className={`${styles['layer-77-550aaeb34569484faddc954ff9307f72-orientation']} ${styles['layer-77-550aaeb34569484faddc954ff9307f72']}`}   >
<section custom-agua-item="layer" className={styles['layer-78-61d7f095f5c9429eb5dd7b135a79850f']}   >
<section custom-agua-item="layer" className={styles['layer-79-8f68307698f142039d3408273cb4c26e']}   ></section>
<section custom-agua-item="layer" className={styles['layer-145-a2f350a88ecd420bb6ea2a9f3f0c8f5c']}   >
<section custom-agua-item="layer" className={styles['layer-80-b7a6087a89014080ad066f7a39bfa2fd']}   onClick={() => openInNewTab("https://www.amazon.com/dp/B0CRLPJ1VK")}>
<p custom-agua-item="text" className={styles['text-41-d3a24977874a465db0ce4497b6b23479']}   >Comprar eBook</p>
</section>
<section custom-agua-item="layer" className={styles['layer-802-cd73ed8baa2e47cdb1be7a8675527c28']}   onClick={() => openInNewTab("https://wa.me/573163155624")}>
<p custom-agua-item="text" className={styles['text-412-983b448703a947f283cab2579ec331dc']}   >Comprar físico</p>
</section>
</section>
</section>
<section custom-agua-item="layer" className={styles['layer-81-9e4ea32d86a14fb180b15f6927296a89']}   >
<section custom-agua-item="layer" className={styles['layer-82-dbca257a0beb438aa014dcd719596ade']}   ></section>
<section custom-agua-item="layer" className={styles['layer-141-f1dd725012c845ecb6ab5b67dbebf1db']}   >
<section custom-agua-item="layer" className={styles['layer-142-4fa25dc1e7fa4864941fe332e978c9ec']}   onClick={() => openInNewTab("https://www.amazon.com/dp/B0CRYBMDZH")}>
<p custom-agua-item="text" className={styles['text-64-6c57be17ae29400ba68d21764650fad0']}   >Comprar eBook</p>
</section>
<section custom-agua-item="layer" className={styles['layer-83-c418b1cdb29444ea8637513474e000fd']}   onClick={() => openInNewTab("https://wa.me/573163155624")}>
<p custom-agua-item="text" className={styles['text-42-fbb52eeb8bda4fe3b0f5d57bc4891c6e']}   >Comprar físico</p>
</section>
</section>
</section>
<section custom-agua-item="layer" className={styles['layer-813-1b8d24f215454cf69a17ebb68a4a9cfa']}   >
<section custom-agua-item="layer" className={styles['layer-823-8253fef986ad4fa5bc609b5474db279a']}   ></section>
<section custom-agua-item="layer" className={styles['layer-1413-cd2a85e7720b45c4b38c9d3ec145d8ab']}   >
<section custom-agua-item="layer" className={styles['layer-1423-9c1563d9477a4afeb5c486eca5f8ea95']}   onClick={() => openInNewTab("https://www.amazon.com/dp/B0CRYBMDZH")}>
<p custom-agua-item="text" className={styles['text-643-9c5d1ec3658c49328ed97544e3f9e9bf']}   >Comprar eBook</p>
</section>
</section>
</section>
</section>
</section>
<section custom-agua-item="layer" className={`${styles['salessection2-orientation']} ${styles['salessection2']}`}   >
<section custom-agua-item="layer" className={styles['layer-1-6b94adfc8c244d42bf06e185f5876e7c']}   >
<section custom-agua-item="layer" className={styles['layer-2-33ef04cff8a94345bbd62cbe600de460']}   >
<p custom-agua-item="text" className={styles['text-2-483691c5a3fe4667a3633f23f0800065']}   >Estas a punto de iniciar una nueva aventura</p>
</section>
<section custom-agua-item="layer" className={styles['layer-3-b61860c7d2f0466e943ccab5bd577237']}   ></section>
</section>
<section custom-agua-item="layer" className={styles['layer-92-4ebf06239dbe45ae9aaa880cc83e566f']}   >
<section custom-agua-item="layer" className={styles['layer-93-e64999df1933436882121f757e5bfb9c']}   >
<p custom-agua-item="text" className={styles['text-46-72ae8cdc996841a2a2b8d166d78fe973']}   >Contamos con formato físico y digital para que conozcas nuevos autores 
¿Qué formato prefires tú?</p>
<section custom-agua-item="layer" className={styles['layer-94-6187d486653f4760941270106c846ce9']}   onClick={() => openInNewTab("https://wa.me/573163155624")}>
<p custom-agua-item="text" className={styles['text-45-bfb48f624f3340959ccdf8a4d7a5b1df']}   >Escríbenos</p>
</section>
</section>
</section>
</section>
</section>

            </main>
        );
    };

    return aguaRender();
}

export default Sales;
            